// variables
// --------------------------

$fa-css-prefix: fa !default;
$fa-style: 900 !default;
$fa-style-family: 'Font Awesome 6 Pro' !default;

$fa-display: inline-block !default;

$fa-fw-width: fa-divide(20em, 16) !default;
$fa-inverse: #fff !default;

$fa-border-color: #eee !default;
$fa-border-padding: 0.2em 0.25em 0.15em !default;
$fa-border-radius: 0.1em !default;
$fa-border-style: solid !default;
$fa-border-width: 0.08em !default;

$fa-size-scale-2xs: 10 !default;
$fa-size-scale-xs: 12 !default;
$fa-size-scale-sm: 14 !default;
$fa-size-scale-base: 16 !default;
$fa-size-scale-lg: 20 !default;
$fa-size-scale-xl: 24 !default;
$fa-size-scale-2xl: 32 !default;

$fa-sizes: (
  '2xs': $fa-size-scale-2xs,
  'xs': $fa-size-scale-xs,
  'sm': $fa-size-scale-sm,
  'lg': $fa-size-scale-lg,
  'xl': $fa-size-scale-xl,
  '2xl': $fa-size-scale-2xl,
) !default;

$fa-li-width: 2em !default;
$fa-li-margin: $fa-li-width * fa-divide(5, 4) !default;

$fa-pull-margin: 0.3em !default;

$fa-primary-opacity: 1 !default;
$fa-secondary-opacity: 0.4 !default;

$fa-stack-vertical-align: middle !default;
$fa-stack-width: ($fa-fw-width * 2) !default;
$fa-stack-z-index: auto !default;

$fa-font-display: block !default;
$fa-font-path: '../webfonts' !default;

$fa-var-angle-down: \f107;
$fa-var-angle-right: \f105;
$fa-var-arrow-right: \f061;
$fa-var-check: \f00c;
$fa-var-chevron-down: \f078;
$fa-var-chevron-left: \f053;
$fa-var-chevron-right: \f054;
$fa-var-chevron-up: \f077;
$fa-var-circle: \f111;
$fa-var-circle-user: \f2bd;
$fa-var-user-circle: \f2bd;
$fa-var-globe: \f0ac;
$fa-var-heart: \f004;
$fa-var-magnifying-glass: \f002;
$fa-var-search: \f002;
$fa-var-minus: \f068;
$fa-var-subtract: \f068;
$fa-var-play: \f04b;
$fa-var-plus: \2b;
$fa-var-add: \2b;
$fa-var-tag: \f02b;
$fa-var-tags: \f02c;
$fa-var-xmark: \f00d;
$fa-var-close: \f00d;
$fa-var-multiply: \f00d;
$fa-var-remove: \f00d;
$fa-var-times: \f00d;

$fa-var-facebook: \f09a;
$fa-var-facebook-f: \f39e;
$fa-var-instagram: \f16d;
$fa-var-twitter: \f099;
$fa-var-x-twitter: \e61b;
$fa-var-youtube: \f167;

$fa-icons: (
  'angle-down': $fa-var-angle-down,
  'angle-right': $fa-var-angle-right,
  'arrow-right': $fa-var-arrow-right,
  'check': $fa-var-check,
  'chevron-down': $fa-var-chevron-down,
  'chevron-left': $fa-var-chevron-left,
  'chevron-right': $fa-var-chevron-right,
  'chevron-up': $fa-var-chevron-up,
  'circle': $fa-var-circle,
  'circle-user': $fa-var-circle-user,
  'user-circle': $fa-var-user-circle,
  'globe': $fa-var-globe,
  'heart': $fa-var-heart,
  'magnifying-glass': $fa-var-magnifying-glass,
  'search': $fa-var-search,
  'minus': $fa-var-minus,
  'subtract': $fa-var-subtract,
  'play': $fa-var-play,
  'plus': $fa-var-plus,
  'add': $fa-var-add,
  'tag': $fa-var-tag,
  'tags': $fa-var-tags,
  'xmark': $fa-var-xmark,
  'close': $fa-var-close,
  'multiply': $fa-var-multiply,
  'remove': $fa-var-remove,
  'times': $fa-var-times,
);

$fa-brand-icons: (
  'facebook': $fa-var-facebook,
  'facebook-f': $fa-var-facebook-f,
  'instagram': $fa-var-instagram,
  'twitter': $fa-var-twitter,
  'x-twitter': $fa-var-x-twitter,
  'youtube': $fa-var-youtube,
);

$fa-custom-icons: ();
